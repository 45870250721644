import { createStore } from "effector";

export const $userList = createStore([]);
$userList.watch((state) => console.log("$userList", state));

export const $userListPlaceId = createStore("amaa25d7c9");

export const $chosenUser = createStore(null);
$chosenUser.watch((state) => console.log("$chosenUser", state));

export const $potentialEmp = createStore([]);
$potentialEmp.watch((state) => console.log("$potentialEmp", state));

export const $showCreateModal = createStore(false);
$showCreateModal.watch((state) => console.log("$showCreateModal", state));

export const $chosenPotentialEmp = createStore(null);
$chosenPotentialEmp.watch((state) => console.log("$chosenPotentialEmp", state));

export const $chosenEmpImgModal = createStore(null);

export const $userListEdu = createStore([]);
$userListEdu.watch((state) => console.log("$userListEdu", state));

export const $userEmpCategory = createStore("Ученик");
