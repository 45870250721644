import { useEffect, useState } from "react";
import { useUnit } from "effector-react";
import {
  $testingFilters,
  $testingTableData,
  changeTestingFiltersEv,
  getTestingTableDataFx,
} from "../../models/testingModel";
import { Table, Image } from "antd";
import { withAuthenticator } from "@aws-amplify/ui-react";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import dayjs from "dayjs";
import style from "./Testing.module.css";
import { useNavigate } from "react-router-dom";
import { $userGroups } from "../../models/userModel";
import Foqus_mini from "../../images/FOQUS_logo_mini.png";
import AWS from "aws-sdk";
import ImageFromS3 from "../../components/ImageFromS3/ImageFromS3";

function parseDate(dateString) {
  const [datePart, timePart] = dateString.split(" ");
  const [day, month, year] = datePart.split("-").map(Number);
  const [hours, minutes, seconds] = timePart.split(":").map(Number);
  return new Date(year, month - 1, day, hours, minutes, seconds);
}

const columns = [
  Table.EXPAND_COLUMN,
  {
    title: "User id",
    dataIndex: "user_id",
    key: "testing-column-user_id",
    sorter: (a, b) => a.data.user_id.localeCompare(b.data.user_id),
    render: (_, record) => {
      const splitted_id = record.data.user_id.split("#")[0];
      // record.visits.sort((a, b) => parseDate(a.date) - parseDate(b.date))
      // for (let i = 1; i < record.visits.length; i++) {
      //   if (record.visits[i].in_out === record.visits[i - 1].in_out) {
      //     record.hasConsecutiveInOut = true;
      //     break;
      //   }
      // }
      // const hasOut = record.visits.some(visit => visit.in_out === 'out');
      return (
        <div
          style={{
            backgroundColor: record.data.hasConsecutiveInOut
              ? "red"
              : "transparent",
          }}
        >
          {splitted_id}
        </div>
      );
      // if (!hasOut) {
      //   record.missingOut = true;
      // }
      // if(record.missingOut){
      //   return <div>{splitted_id}</div>
      // } else {
      //
      // }
    },
  },
  // Table.SELECTION_COLUMN,
  // {
  //   title: 'photo',
  //   dataIndex: 's3_object_key',
  //   key: 'photo',
  //   render: (s3_object_key, record) => <ImageFromS3 s3Bucket={record.s3_bucket_name} s3Key={s3_object_key} />
  // },
  {
    title: "User name",
    dataIndex: "user_name",
    key: "testing-column-user_name",
    sorter: (a, b) => a.data.user_name.localeCompare(b.data.user_name),
    render: (_, record) => {
      return record.data.user_name;
    },
  },
  {
    title: "User surname",
    dataIndex: "user_surname",
    key: "testing-column-user_surname",
    sorter: (a, b) => a.data.user_surname.localeCompare(b.data.user_surname),
    render: (_, record) => {
      return record.data.user_surname;
    },
  },

  // {
  // 	title: 'Gender',
  // 	dataIndex: 'gender',
  // 	key: 'testing-column-gender',
  // 	sorter: (a,b) => a.gender.localeCompare(b.gender)
  // },
  {
    title: "User gender",
    dataIndex: "user_gender",
    key: "testing-column-user_gender",
    sorter: (a, b) => a.data.user_gender.localeCompare(b.data.user_gender),
    render: (_, record) => {
      return record.data.user_gender;
    },
  },
  // {
  // 	title: 'Age high',
  // 	dataIndex: 'age_high',
  // 	key: 'testing-column-age_high',
  // 	sorter: (a,b) => a.age_high - b.age_high
  // },
  // {
  // 	title: 'Age low',
  // 	dataIndex: 'age_low',
  // 	key: 'testing-column-age_low',
  // 	sorter: (a,b) => a.age_low - b.age_low
  // },
  // {
  // 	title: 'Date',
  // 	dataIndex: 'date',
  // 	key: 'testing-column-date',
  // 	sorter: (a,b) => a.date.localeCompare(b.date)
  // },
  // {
  // 	title: 'In out',
  // 	dataIndex: 'in_out',
  // 	key: 'testing-column-in_out',
  // 	sorter: (a,b) => a.in_out.localeCompare(b.in_out)
  // },
  // {
  // 	title: 'Emotion',
  // 	dataIndex: 'emotion',
  // 	key: 'testing-column-emotion',
  // 	sorter: (a,b) => a.emotion.localeCompare(b.emotion)
  // },
  // {
  // 	title: 'Service',
  // 	dataIndex: 'service',
  // 	key: 'testing-column-service',
  // 	sorter: (a,b) => a.service.localeCompare(b.service)
  // },
  {
    title: "In",
    dataIndex: "checkin_at_count_in",
    key: "testing-column-checkin_at_count_in",
    sorter: (a, b) => a.data.checkin_at_count_in - b.data.checkin_at_count_in,
    render: (_, record) => {
      return record.data.checkin_at_count_in;
    },
  },
  {
    title: "Out",
    dataIndex: "checkin_at_count_out",
    key: "testing-column-checkin_at_count_out",
    sorter: (a, b) => a.data.checkin_at_count_out - b.data.checkin_at_count_out,
    render: (_, record) => {
      return record.data.checkin_at_count_out;
    },
  },
  {
    title: "Earliest In",
    dataIndex: "checkin_at_min_in",
    key: "testing-column-checkin_at_min_in",
    sorter: (a, b) =>
      a.data.checkin_at_min_in.localeCompare(b.data.checkin_at_min_in),
    render: (_, record) => {
      return record.data.checkin_at_min_in;
    },
  },
  {
    title: "Latest In",
    dataIndex: "checkin_at_max_in",
    key: "testing-column-checkin_at_max_in",
    sorter: (a, b) =>
      a.data.checkin_at_max_in.localeCompare(b.data.checkin_at_max_in),
    render: (_, record) => {
      return record.data.checkin_at_max_in;
    },
  },
  {
    title: "Earliest Out",
    dataIndex: "checkin_at_min_out",
    key: "testing-column-checkin_at_min_out",
    sorter: (a, b) =>
      a.data.checkin_at_min_out.localeCompare(b.data.checkin_at_min_out),
    render: (_, record) => {
      return record.data.checkin_at_min_out;
    },
  },
  {
    title: "Latest Out",
    dataIndex: "checkin_at_max_out",
    key: "testing-column-checkin_at_max_out",
    sorter: (a, b) =>
      a.data.checkin_at_max_out.localeCompare(b.data.checkin_at_max_out),
    render: (_, record) => {
      return record.data.checkin_at_max_out;
    },
  },
];

const checkInOptions = [
  {
    value: "employee",
    label: "Employee",
  },
  {
    value: "visitor",
    label: "Visitor",
  },
];
const cliendIdOptions = [
  {
    value: "klba36t1h2",
    label: "Kolibrix Спартака, 26",
  },
  {
    value: "klba36x2c2",
    label: "Kolibrix Жунисова, 2/13 к1",
  },
  {
    value: "amaa25d7c9",
    label: "Amanat Достык, 232/1",
  },
  {
    value: "crtz05x7d2",
    label: "Creatti, ул.Мангилик ел, д. 59",
  },
];

function Testing() {
  const testingTableData = useUnit($testingTableData);
  const changeTestingFilters = useUnit(changeTestingFiltersEv);
  const testingFilters = useUnit($testingFilters);
  const loading = useUnit(getTestingTableDataFx.pending);
  const navigate = useNavigate();
  const userGroups = useUnit($userGroups);
  const [groupedTableData, setGroupedTableData] = useState([]);
  // const [copied, setCopied] = useState(false);
  // const textToCopy = "This is the text to be copied!";

  // const handleCopy = () => {
  //   navigator.clipboard.writeText(textToCopy)
  //     .then(() => {
  //       setCopied(true);
  //       setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
  //     })
  //     .catch(err => {
  //       console.error('Failed to copy: ', err);
  //     });
  // };

  const handleCopy = (hiddenText) => {
    navigator.clipboard
      .writeText(hiddenText)
      .then(() => {
        console.log("Text copied to clipboard:", hiddenText);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  useEffect(() => {
    if (testingTableData.length === 0) {
      getTestingTableDataFx();
    }
    setGroupedTableData(testingTableData);
  }, [testingTableData]);

  // if(testingTableData.length > 0){
  //   if(groupedTableData.length === 0){
  //     setGroupedTableData(groupAndAddVisitsByUserId(testingTableData));
  //   }
  // }
  // debugger
  console.log("groupedTableData: - ", groupedTableData);
  useEffect(() => {
    if (Array.isArray(userGroups) && !userGroups.includes("portal_admin")) {
      navigate("/dashboard");
    }
  }, [userGroups, navigate]);

  if (!userGroups || userGroups?.length === 0) {
    return null;
  }

  const negativeEmotions = ["SAD", "ANGRY", "FEAR", "DISGUSTED"];

  function parseDate(dateString) {
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("-").map(Number);
    const [hours, minutes, seconds] = timePart.split(":").map(Number);
    return new Date(year, month - 1, day, hours, minutes, seconds);
  }

  console.log("testingFilters: - ", testingFilters);

  return (
    <div>
      <div className={style.filtration_wrapper}>
        <div className={style.filtration_title}>Filtration</div>
        <CustomSelect
          options={checkInOptions}
          onSelect={changeTestingFilters}
          field={"checkin_status"}
          placeholder="Check in status"
          value={testingFilters.checkin_status}
          allowClear
        />
        <CustomSelect
          options={cliendIdOptions}
          onSelect={changeTestingFilters}
          field={"client_id"}
          placeholder="Client"
          value={testingFilters.client_id}
        />
        <CustomSelect
          selectType={"date"}
          onSelect={changeTestingFilters}
          field={"date"}
          value={dayjs(new Date(testingFilters.date))}
          allowClear={false}
        />
      </div>
      <Table
        style={{
          width: "100%!important",
        }}
        loading={loading}
        columns={columns}
        dataSource={groupedTableData}
        expandable={{
          expandedRowRender: (record) =>
            record.visits.map((visit, index) => {
              return (
                <div
                  key={"expandable-visit-" + record.data.user_id + "-" + index}
                  style={{
                    display: "flex",
                    // flexWrap: 'wrap',
                    // maxWidth: '80vw',
                    // justifyContent: 'space-between',
                  }}
                >
                  {visit.pairs.length === 2 ? (
                    <div
                      className={"PairBox"}
                      style={{
                        display: "flex",
                      }}
                    >
                      {visit.pairs.map((pair) => {
                        return (
                          <div
                            className={"PairBox"}
                            style={{
                              display: "flex",
                            }}
                          >
                            {pair.in_out === "in" ? (
                              <div
                                className={"PairIn"}
                                style={{
                                  minWidth: "350px",
                                }}
                              >
                                <div
                                  className={pair.in_out}
                                  key={`testing-visit-${record.data.user_id}-${visit.date}`}
                                  style={{
                                    marginRight: "25px",
                                  }}
                                >
                                  <div
                                    key={pair.date} // Assuming visit has a unique identifier like an id
                                    style={{
                                      margin: "0px 0px 50px",
                                      display: "inline-block",
                                      borderRadius: "8px",
                                      border: "1px solid",
                                      padding: "15px",
                                    }}
                                  >
                                    {/*Gender, Age high, Age low, Date, In out, Emotion, filename*/}
                                    <div>
                                      User similarity: - {pair.user_similarity}
                                    </div>

                                    <div>Age high: - {pair.age_high}</div>
                                    <div>Age low: - {pair.age_low}</div>
                                    <div>Gender: - {pair.gender}</div>
                                    <div>
                                      File name:
                                      <button
                                        onClick={() =>
                                          handleCopy(pair.file_name)
                                        }
                                        style={{
                                          marginLeft: "15px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Click to copy
                                      </button>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span style={{ marginRight: "15px" }}>
                                        {pair.checkin_at}
                                      </span>
                                      <span style={{ marginRight: "15px" }}>
                                        -
                                      </span>
                                      {pair.in_out === "in" ? (
                                        <span
                                          style={{
                                            backgroundColor: "green",
                                            color: "white",
                                            padding: "5px 25px",
                                            borderRadius: "8px",
                                          }}
                                        >
                                          {pair.in_out}
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            backgroundColor: "red",
                                            color: "white",
                                            padding: "5px 25px",
                                            borderRadius: "8px",
                                          }}
                                        >
                                          {pair.in_out}
                                        </span>
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <span style={{ marginRight: "15px" }}>
                                        Emotion
                                      </span>
                                      <span style={{ marginRight: "15px" }}>
                                        -
                                      </span>
                                      <span
                                        style={{
                                          backgroundColor:
                                            negativeEmotions.includes(
                                              pair.emotion,
                                            )
                                              ? "red"
                                              : "transparent",
                                          color: negativeEmotions.includes(
                                            pair.emotion,
                                          )
                                            ? "white"
                                            : "black",
                                          padding: "5px 25px",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        {pair.emotion}
                                      </span>
                                    </div>
                                    <ImageFromS3
                                      // s3Bucket={pair.s3_bucket_name}
                                      // s3Key={pair.s3_object_key}
                                      presigned_url={pair.presigned_url}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {pair.in_out === "out" ? (
                              <div
                                className={pair.in_out}
                                key={`testing-visit-${record.data.user_id}-${visit.date}`}
                                style={{
                                  marginRight: "25px",
                                }}
                              >
                                <div
                                  key={pair.date} // Assuming visit has a unique identifier like an id
                                  style={{
                                    margin: "0px 0px 50px",
                                    display: "inline-block",
                                    borderRadius: "8px",
                                    border: "1px solid",
                                    padding: "15px",
                                  }}
                                >
                                  {/*Gender, Age high, Age low, Date, In out, Emotion, filename*/}
                                  <div>
                                    User similarity: - {pair.user_similarity}
                                  </div>

                                  <div>Age high: - {pair.age_high}</div>
                                  <div>Age low: - {pair.age_low}</div>
                                  <div>Gender: - {pair.gender}</div>
                                  <div>
                                    File name:
                                    <button
                                      onClick={() => handleCopy(pair.file_name)}
                                      style={{
                                        marginLeft: "15px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Click to copy
                                    </button>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span style={{ marginRight: "15px" }}>
                                      {pair.checkin_at}
                                    </span>
                                    <span style={{ marginRight: "15px" }}>
                                      -
                                    </span>
                                    {pair.in_out === "in" ? (
                                      <span
                                        style={{
                                          backgroundColor: "green",
                                          color: "white",
                                          padding: "5px 25px",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        {pair.in_out}
                                      </span>
                                    ) : (
                                      <span
                                        style={{
                                          backgroundColor: "red",
                                          color: "white",
                                          padding: "5px 25px",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        {pair.in_out}
                                      </span>
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <span style={{ marginRight: "15px" }}>
                                      Emotion
                                    </span>
                                    <span style={{ marginRight: "15px" }}>
                                      -
                                    </span>
                                    <span
                                      style={{
                                        backgroundColor:
                                          negativeEmotions.includes(
                                            pair.emotion,
                                          )
                                            ? "red"
                                            : "transparent",
                                        color: negativeEmotions.includes(
                                          pair.emotion,
                                        )
                                          ? "white"
                                          : "black",
                                        padding: "5px 25px",
                                        borderRadius: "8px",
                                      }}
                                    >
                                      {pair.emotion}
                                    </span>
                                  </div>
                                  {/*Here example for taking photos*/}
                                  <ImageFromS3
                                    // s3Bucket={pair.s3_bucket_name}
                                    // s3Key={pair.s3_object_key}
                                    presigned_url={pair.presigned_url}
                                  />
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    visit.pairs.map((pair) => {
                      if (pair[0]) {
                        return (
                          <div
                            className={"PairBox"}
                            style={{
                              display: "flex",
                            }}
                          >
                            {pair[0] === "in" ? (
                              <div
                                className={
                                  pair.in_out === "in" ? "PairIn" : "PairOut"
                                }
                                style={{
                                  minWidth: "350px",
                                }}
                              >
                                <div
                                  className={pair.in_out}
                                  key={`testing-visit-${record.data.user_id}-${visit.date}`}
                                  style={{
                                    marginRight: "25px",
                                  }}
                                >
                                  <div
                                    key={pair.date} // Assuming visit has a unique identifier like an id
                                    style={{
                                      margin: "0px 0px 50px",
                                      display: "inline-block",
                                      borderRadius: "8px",
                                      border: "1px solid",
                                      padding: "15px",
                                    }}
                                  >
                                    {/*Gender, Age high, Age low, Date, In out, Emotion, filename*/}
                                    <div>
                                      User similarity: - {pair.user_similarity}
                                    </div>

                                    <div>Age high: - {pair.age_high}</div>
                                    <div>Age low: - {pair.age_low}</div>
                                    <div>Gender: - {pair.gender}</div>
                                    <div>
                                      File name:
                                      <button
                                        onClick={() =>
                                          handleCopy(pair.file_name)
                                        }
                                        style={{
                                          marginLeft: "15px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Click to copy
                                      </button>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span style={{ marginRight: "15px" }}>
                                        {pair.checkin_at}
                                      </span>
                                      <span style={{ marginRight: "15px" }}>
                                        -
                                      </span>
                                      {pair.in_out === "in" ? (
                                        <span
                                          style={{
                                            backgroundColor: "green",
                                            color: "white",
                                            padding: "5px 25px",
                                            borderRadius: "8px",
                                          }}
                                        >
                                          {pair.in_out}
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            backgroundColor: "red",
                                            color: "white",
                                            padding: "5px 25px",
                                            borderRadius: "8px",
                                          }}
                                        >
                                          {pair.in_out}
                                        </span>
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <span style={{ marginRight: "15px" }}>
                                        Emotion
                                      </span>
                                      <span style={{ marginRight: "15px" }}>
                                        -
                                      </span>
                                      <span
                                        style={{
                                          backgroundColor:
                                            negativeEmotions.includes(
                                              pair.emotion,
                                            )
                                              ? "red"
                                              : "transparent",
                                          color: negativeEmotions.includes(
                                            pair.emotion,
                                          )
                                            ? "white"
                                            : "black",
                                          padding: "5px 25px",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        {pair.emotion}
                                      </span>
                                    </div>
                                    <ImageFromS3
                                      // s3Bucket={pair.s3_bucket_name}
                                      // s3Key={pair.s3_object_key}
                                      presigned_url={pair.presigned_url}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div
                                className={"PairIn"}
                                style={{
                                  minWidth: "350px",
                                }}
                              ></div>
                            )}
                            {pair[1] === "out" ? (
                              <div
                                className={
                                  pair.in_out === "in" ? "PairIn" : "PairOut"
                                }
                                key={`testing-visit-${record.data.user_id}-${visit.date}`}
                                style={{
                                  marginRight: "25px",
                                }}
                              >
                                <div
                                  key={pair.date} // Assuming visit has a unique identifier like an id
                                  style={{
                                    margin: "0px 0px 50px",
                                    display: "inline-block",
                                    borderRadius: "8px",
                                    border: "1px solid",
                                    padding: "15px",
                                  }}
                                >
                                  {/*Gender, Age high, Age low, Date, In out, Emotion, filename*/}
                                  <div>
                                    User similarity: - {pair.user_similarity}
                                  </div>

                                  <div>Age high: - {pair.age_high}</div>
                                  <div>Age low: - {pair.age_low}</div>
                                  <div>Gender: - {pair.gender}</div>
                                  <div>
                                    File name:
                                    <button
                                      onClick={() => handleCopy(pair.file_name)}
                                      style={{
                                        marginLeft: "15px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Click to copy
                                    </button>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span style={{ marginRight: "15px" }}>
                                      {pair.checkin_at}
                                    </span>
                                    <span style={{ marginRight: "15px" }}>
                                      -
                                    </span>
                                    {pair.in_out === "in" ? (
                                      <span
                                        style={{
                                          backgroundColor: "green",
                                          color: "white",
                                          padding: "5px 25px",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        {pair.in_out}
                                      </span>
                                    ) : (
                                      <span
                                        style={{
                                          backgroundColor: "red",
                                          color: "white",
                                          padding: "5px 25px",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        {pair.in_out}
                                      </span>
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <span style={{ marginRight: "15px" }}>
                                      Emotion
                                    </span>
                                    <span style={{ marginRight: "15px" }}>
                                      -
                                    </span>
                                    <span
                                      style={{
                                        backgroundColor:
                                          negativeEmotions.includes(
                                            pair.emotion,
                                          )
                                            ? "red"
                                            : "transparent",
                                        color: negativeEmotions.includes(
                                          pair.emotion,
                                        )
                                          ? "white"
                                          : "black",
                                        padding: "5px 25px",
                                        borderRadius: "8px",
                                      }}
                                    >
                                      {pair.emotion}
                                    </span>
                                  </div>
                                  <ImageFromS3
                                    // s3Bucket={pair.s3_bucket_name}
                                    // s3Key={pair.s3_object_key}
                                    presigned_url={pair.presigned_url}
                                  />
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      } else {
                        if (pair.in_out === "out") {
                          return (
                            <div
                              className={"PairBox"}
                              style={{
                                display: "flex",
                              }}
                            >
                              <div
                                className={"PairIn"}
                                style={{
                                  minWidth: "350px",
                                }}
                              ></div>
                              <div
                                className={
                                  pair.in_out === "in" ? "PairIn" : "PairOut"
                                }
                                key={`testing-visit-${record.data.user_id}-${visit.date}`}
                                style={{
                                  marginRight: "25px",
                                }}
                              >
                                <div
                                  key={pair.date} // Assuming visit has a unique identifier like an id
                                  style={{
                                    margin: "0px 0px 50px",
                                    display: "inline-block",
                                    borderRadius: "8px",
                                    border: "1px solid",
                                    padding: "15px",
                                  }}
                                >
                                  {/*Gender, Age high, Age low, Date, In out, Emotion, filename*/}
                                  <div>
                                    User similarity: - {pair.user_similarity}
                                  </div>

                                  <div>Age high: - {pair.age_high}</div>
                                  <div>Age low: - {pair.age_low}</div>
                                  <div>Gender: - {pair.gender}</div>
                                  <div>
                                    File name:
                                    <button
                                      onClick={() => handleCopy(pair.file_name)}
                                      style={{
                                        marginLeft: "15px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Click to copy
                                    </button>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span style={{ marginRight: "15px" }}>
                                      {pair.checkin_at}
                                    </span>
                                    <span style={{ marginRight: "15px" }}>
                                      -
                                    </span>
                                    {pair.in_out === "in" ? (
                                      <span
                                        style={{
                                          backgroundColor: "green",
                                          color: "white",
                                          padding: "5px 25px",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        {pair.in_out}
                                      </span>
                                    ) : (
                                      <span
                                        style={{
                                          backgroundColor: "red",
                                          color: "white",
                                          padding: "5px 25px",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        {pair.in_out}
                                      </span>
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <span style={{ marginRight: "15px" }}>
                                      Emotion
                                    </span>
                                    <span style={{ marginRight: "15px" }}>
                                      -
                                    </span>
                                    <span
                                      style={{
                                        backgroundColor:
                                          negativeEmotions.includes(
                                            pair.emotion,
                                          )
                                            ? "red"
                                            : "transparent",
                                        color: negativeEmotions.includes(
                                          pair.emotion,
                                        )
                                          ? "white"
                                          : "black",
                                        padding: "5px 25px",
                                        borderRadius: "8px",
                                      }}
                                    >
                                      {pair.emotion}
                                    </span>
                                  </div>
                                  <ImageFromS3
                                    // s3Bucket={pair.s3_bucket_name}
                                    // s3Key={pair.s3_object_key}
                                    presigned_url={pair.presigned_url}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              className={"PairBox"}
                              style={{
                                display: "flex",
                              }}
                            >
                              <div
                                className={
                                  pair.in_out === "in" ? "PairIn" : "PairOut"
                                }
                                key={`testing-visit-${record.data.user_id}-${visit.date}`}
                                style={{
                                  marginRight: "25px",
                                }}
                              >
                                <div
                                  key={pair.date} // Assuming visit has a unique identifier like an id
                                  style={{
                                    margin: "0px 0px 50px",
                                    display: "inline-block",
                                    borderRadius: "8px",
                                    border: "1px solid",
                                    padding: "15px",
                                  }}
                                >
                                  {/*Gender, Age high, Age low, Date, In out, Emotion, filename*/}
                                  <div>
                                    User similarity: - {pair.user_similarity}
                                  </div>

                                  <div>Age high: - {pair.age_high}</div>
                                  <div>Age low: - {pair.age_low}</div>
                                  <div>Gender: - {pair.gender}</div>
                                  <div>
                                    File name:
                                    <button
                                      onClick={() => handleCopy(pair.file_name)}
                                      style={{
                                        marginLeft: "15px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Click to copy
                                    </button>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span style={{ marginRight: "15px" }}>
                                      {pair.checkin_at}
                                    </span>
                                    <span style={{ marginRight: "15px" }}>
                                      -
                                    </span>
                                    {pair.in_out === "in" ? (
                                      <span
                                        style={{
                                          backgroundColor: "green",
                                          color: "white",
                                          padding: "5px 25px",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        {pair.in_out}
                                      </span>
                                    ) : (
                                      <span
                                        style={{
                                          backgroundColor: "red",
                                          color: "white",
                                          padding: "5px 25px",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        {pair.in_out}
                                      </span>
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <span style={{ marginRight: "15px" }}>
                                      Emotion
                                    </span>
                                    <span style={{ marginRight: "15px" }}>
                                      -
                                    </span>
                                    <span
                                      style={{
                                        backgroundColor:
                                          negativeEmotions.includes(
                                            pair.emotion,
                                          )
                                            ? "red"
                                            : "transparent",
                                        color: negativeEmotions.includes(
                                          pair.emotion,
                                        )
                                          ? "white"
                                          : "black",
                                        padding: "5px 25px",
                                        borderRadius: "8px",
                                      }}
                                    >
                                      {pair.emotion}
                                    </span>
                                  </div>
                                  <ImageFromS3
                                    // s3Bucket={pair.s3_bucket_name}
                                    // s3Key={pair.s3_object_key}
                                    presigned_url={pair.presigned_url}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        }
                      }
                    })
                  )}
                </div>
              );
            }),
        }}
        // expandable={{
        //   expandedRowRender: (record) => (
        //     <div
        //       style={{
        //         display: 'flex',
        //         // flexWrap: 'wrap',
        //         // maxWidth: '80vw',
        //         // justifyContent: 'space-between',
        //       }}
        //     >{
        //       record.visits.map(visit => (
        //         // visit.map(pairs => (
        //
        //           <div
        //             className={'In'}
        //             style={{
        //               marginRight: '25px'
        //             }}
        //           >
        //             {/*pairs[0].in_out === 'in' ?*/}
        //             <div key={`testing-visit-${record.data.user_id}-${visit.date}`}>
        //               <div
        //                 key={visit.date} // Assuming visit has a unique identifier like an id
        //                 style={{
        //                   margin: '0px 0px 50px',
        //                   display: 'inline-block',
        //                   borderRadius: '8px',
        //                   border: '1px solid',
        //                   padding: '15px',
        //                 }}
        //               >
        //                 {/*Gender, Age high, Age low, Date, In out, Emotion, filename*/}
        //                 <div>
        //                   Age high: - {visit.age_high}
        //                 </div>
        //                 <div>
        //                   Age low: - {visit.age_low}
        //                 </div>
        //                 <div>
        //                   Gender: - {visit.gender}
        //                 </div>
        //                 <div>
        //                   File name:
        //                   <button
        //                     onClick={() => handleCopy(visit.file_name)}
        //                     style={{
        //                       marginLeft: '15px',
        //                       cursor: 'pointer'
        //                     }}
        //                   >Click to copy</button>
        //                 </div>
        //                 <div
        //                   style={{
        //                     display: 'flex',
        //                     justifyContent: 'flex-start',
        //                     alignItems: 'center',
        //                   }}
        //                 >
        //                   <span style={{marginRight: '15px'}}>{visit.date}</span>
        //                   <span style={{marginRight: '15px'}}>-</span>
        //                   {visit.in_out === 'in' ?
        //                     <span style={{
        //                       backgroundColor: 'green',
        //                       color: 'white',
        //                       padding: '5px 25px',
        //                       borderRadius: '8px',
        //                     }}>{visit.in_out}</span>
        //                     :
        //                     <span style={{
        //                       backgroundColor: 'red',
        //                       color: 'white',
        //                       padding: '5px 25px',
        //                       borderRadius: '8px',
        //                     }}>{visit.in_out}</span>
        //                   }
        //
        //                 </div>
        //                 <div style={{
        //                   marginBottom: '10px',
        //                 }}>
        //                   <span style={{marginRight: '15px'}}>Emotion</span>
        //                   <span style={{marginRight: '15px'}}>-</span>
        //                   <span style={{
        //                     backgroundColor: negativeEmotions.includes(visit.emotion) ? 'red' : 'transparent',
        //                     color: negativeEmotions.includes(visit.emotion) ? 'white' : 'black',
        //                     padding: '5px 25px',
        //                     borderRadius: '8px'
        //                   }}
        //                   >{visit.emotion}</span>
        //                 </div>
        //                 <ImageFromS3 s3Bucket={visit.s3_bucket_name} s3Key={visit.s3_object_key}/>
        //               </div>
        //             </div>
        //             : ''
        //             {/*pairs[0].in_out === 'out' ?*/}
        //             {/*<div key={`testing-visit-${record.data.user_id}-${visit.date}`}>*/}
        //             {/*  <div*/}
        //             {/*    key={visit.date} // Assuming visit has a unique identifier like an id*/}
        //             {/*    style={{*/}
        //             {/*      margin: '0px 0px 50px',*/}
        //             {/*      display: 'inline-block',*/}
        //             {/*      borderRadius: '8px',*/}
        //             {/*      border: '1px solid',*/}
        //             {/*      padding: '15px',*/}
        //             {/*    }}*/}
        //             {/*  >*/}
        //             {/*    /!*Gender, Age high, Age low, Date, In out, Emotion, filename*!/*/}
        //             {/*    <div>*/}
        //             {/*      Age high: - {visit.age_high}*/}
        //             {/*    </div>*/}
        //             {/*    <div>*/}
        //             {/*      Age low: - {visit.age_low}*/}
        //             {/*    </div>*/}
        //             {/*    <div>*/}
        //             {/*      Gender: - {visit.gender}*/}
        //             {/*    </div>*/}
        //             {/*    <div>*/}
        //             {/*      File name:*/}
        //             {/*      <button*/}
        //             {/*        onClick={() => handleCopy(visit.file_name)}*/}
        //             {/*        style={{*/}
        //             {/*          marginLeft: '15px',*/}
        //             {/*          cursor: 'pointer'*/}
        //             {/*        }}*/}
        //             {/*      >Click to copy</button>*/}
        //             {/*    </div>*/}
        //             {/*    <div*/}
        //             {/*      style={{*/}
        //             {/*        display: 'flex',*/}
        //             {/*        justifyContent: 'flex-start',*/}
        //             {/*        alignItems: 'center',*/}
        //             {/*      }}*/}
        //             {/*    >*/}
        //             {/*      <span style={{marginRight: '15px'}}>{visit.date}</span>*/}
        //             {/*      <span style={{marginRight: '15px'}}>-</span>*/}
        //             {/*      {visit.in_out === 'in' ?*/}
        //             {/*        <span style={{*/}
        //             {/*          backgroundColor: 'green',*/}
        //             {/*          color: 'white',*/}
        //             {/*          padding: '5px 25px',*/}
        //             {/*          borderRadius: '8px',*/}
        //             {/*        }}>{visit.in_out}</span>*/}
        //             {/*        :*/}
        //             {/*        <span style={{*/}
        //             {/*          backgroundColor: 'red',*/}
        //             {/*          color: 'white',*/}
        //             {/*          padding: '5px 25px',*/}
        //             {/*          borderRadius: '8px',*/}
        //             {/*        }}>{visit.in_out}</span>*/}
        //             {/*      }*/}
        //
        //             {/*    </div>*/}
        //             {/*    <div style={{*/}
        //             {/*      marginBottom: '10px',*/}
        //             {/*    }}>*/}
        //             {/*      <span style={{marginRight: '15px'}}>Emotion</span>*/}
        //             {/*      <span style={{marginRight: '15px'}}>-</span>*/}
        //             {/*      <span style={{*/}
        //             {/*        backgroundColor: negativeEmotions.includes(visit.emotion) ? 'red' : 'transparent',*/}
        //             {/*        color: negativeEmotions.includes(visit.emotion) ? 'white' : 'black',*/}
        //             {/*        padding: '5px 25px',*/}
        //             {/*        borderRadius: '8px'*/}
        //             {/*      }}*/}
        //             {/*      >{visit.emotion}</span>*/}
        //             {/*    </div>*/}
        //             {/*    <ImageFromS3 s3Bucket={visit.s3_bucket_name} s3Key={visit.s3_object_key}/>*/}
        //             {/*  </div>*/}
        //             {/*</div>*/}
        //             {/*: ''*/}
        //           </div>
        //         // ))
        //       ))
        //     }
        //
        //
        //       {/*<div*/}
        //       {/*  className={'In'}*/}
        //       {/*  style={{*/}
        //       {/*    marginRight: '25px'*/}
        //       {/*  }}*/}
        //       {/*>*/}
        //       {/*  {*/}
        //       {/*    record.data.visits.sort((a, b) => parseDate(a.date) - parseDate(b.date)).map(visit => (*/}
        //       {/*      visit.in_out === 'in' ?*/}
        //       {/*        <div key={`testing-visit-${record.data.user_id}-${visit.date}`}>*/}
        //       {/*          <div*/}
        //       {/*            key={visit.date} // Assuming visit has a unique identifier like an id*/}
        //       {/*            style={{*/}
        //       {/*              margin: '0px 0px 50px',*/}
        //       {/*              display: 'inline-block',*/}
        //       {/*              borderRadius: '8px',*/}
        //       {/*              border: '1px solid',*/}
        //       {/*              padding: '15px',*/}
        //       {/*            }}*/}
        //       {/*          >*/}
        //       {/*            /!*Gender, Age high, Age low, Date, In out, Emotion, filename*!/*/}
        //       {/*            <div>*/}
        //       {/*              Age high: - {visit.age_high}*/}
        //       {/*            </div>*/}
        //       {/*            <div>*/}
        //       {/*              Age low: - {visit.age_low}*/}
        //       {/*            </div>*/}
        //       {/*            <div>*/}
        //       {/*              Gender: - {visit.gender}*/}
        //       {/*            </div>*/}
        //       {/*            <div>*/}
        //       {/*              File name:*/}
        //       {/*              <button*/}
        //       {/*                onClick={() => handleCopy(visit.file_name)}*/}
        //       {/*                style={{*/}
        //       {/*                  marginLeft: '15px',*/}
        //       {/*                  cursor: 'pointer'*/}
        //       {/*                }}*/}
        //       {/*              >Click to copy</button>*/}
        //       {/*            </div>*/}
        //       {/*            <div*/}
        //       {/*              style={{*/}
        //       {/*                display: 'flex',*/}
        //       {/*                justifyContent: 'flex-start',*/}
        //       {/*                alignItems: 'center',*/}
        //       {/*              }}*/}
        //       {/*            >*/}
        //       {/*              <span style={{marginRight: '15px'}}>{visit.date}</span>*/}
        //       {/*              <span style={{marginRight: '15px'}}>-</span>*/}
        //       {/*              {visit.in_out === 'in' ?*/}
        //       {/*                <span style={{*/}
        //       {/*                  backgroundColor: 'green',*/}
        //       {/*                  color: 'white',*/}
        //       {/*                  padding: '5px 25px',*/}
        //       {/*                  borderRadius: '8px',*/}
        //       {/*                }}>{visit.in_out}</span>*/}
        //       {/*                :*/}
        //       {/*                <span style={{*/}
        //       {/*                  backgroundColor: 'red',*/}
        //       {/*                  color: 'white',*/}
        //       {/*                  padding: '5px 25px',*/}
        //       {/*                  borderRadius: '8px',*/}
        //       {/*                }}>{visit.in_out}</span>*/}
        //       {/*              }*/}
        //
        //       {/*            </div>*/}
        //       {/*            <div style={{*/}
        //       {/*              marginBottom: '10px',*/}
        //       {/*            }}>*/}
        //       {/*              <span style={{marginRight: '15px'}}>Emotion</span>*/}
        //       {/*              <span style={{marginRight: '15px'}}>-</span>*/}
        //       {/*              <span style={{*/}
        //       {/*                backgroundColor: negativeEmotions.includes(visit.emotion) ? 'red' : 'transparent',*/}
        //       {/*                color: negativeEmotions.includes(visit.emotion) ? 'white' : 'black',*/}
        //       {/*                padding: '5px 25px',*/}
        //       {/*                borderRadius: '8px'*/}
        //       {/*              }}*/}
        //       {/*              >{visit.emotion}</span>*/}
        //       {/*            </div>*/}
        //       {/*            <ImageFromS3 s3Bucket={visit.s3_bucket_name} s3Key={visit.s3_object_key}/>*/}
        //       {/*          </div>*/}
        //       {/*        </div>*/}
        //       {/*        : ''*/}
        //       {/*    ))*/}
        //       {/*  }*/}
        //
        //       {/*</div>*/}
        //       {/*<div className={'Out'}>*/}
        //       {/*{*/}
        //       {/*  record.data.visits.sort((a, b) => parseDate(a.date) - parseDate(b.date)).map(visit => (*/}
        //       {/*    visit.in_out === 'out' ?*/}
        //       {/*    <div key={`testing-visit-${record.data.user_id}-${visit.date}`}>*/}
        //       {/*      <div*/}
        //       {/*        key={visit.date} // Assuming visit has a unique identifier like an id*/}
        //       {/*        style={{*/}
        //       {/*          margin: '0px 0px 50px',*/}
        //       {/*          display: 'inline-block',*/}
        //       {/*          borderRadius: '8px',*/}
        //       {/*          border: '1px solid',*/}
        //       {/*          padding: '15px',*/}
        //       {/*        }}*/}
        //       {/*      >*/}
        //       {/*        /!*Gender, Age high, Age low, Date, In out, Emotion, filename*!/*/}
        //       {/*        <div>*/}
        //       {/*          Age high: - {visit.age_high}*/}
        //       {/*        </div>*/}
        //       {/*        <div>*/}
        //       {/*          Age low: - {visit.age_low}*/}
        //       {/*        </div>*/}
        //       {/*        <div>*/}
        //       {/*          Gender: - {visit.gender}*/}
        //       {/*        </div>*/}
        //       {/*        <div>*/}
        //       {/*          File name:*/}
        //       {/*          <button*/}
        //       {/*            onClick={() => handleCopy(visit.file_name)}*/}
        //       {/*            style={{*/}
        //       {/*              marginLeft: '15px',*/}
        //       {/*              cursor: 'pointer'*/}
        //       {/*            }}*/}
        //       {/*          >Click to copy</button>*/}
        //       {/*        </div>*/}
        //       {/*        <div*/}
        //       {/*          style={{*/}
        //       {/*            display: 'flex',*/}
        //       {/*            justifyContent: 'flex-start',*/}
        //       {/*            alignItems: 'center',*/}
        //       {/*          }}*/}
        //       {/*        >*/}
        //       {/*          <span style={{marginRight: '15px'}}>{visit.date}</span>*/}
        //       {/*          <span style={{marginRight: '15px'}}>-</span>*/}
        //       {/*          {visit.in_out === 'in' ?*/}
        //       {/*            <span style={{*/}
        //       {/*              backgroundColor: 'green',*/}
        //       {/*              color: 'white',*/}
        //       {/*              padding: '5px 25px',*/}
        //       {/*              borderRadius: '8px',*/}
        //       {/*            }}>{visit.in_out}</span>*/}
        //       {/*            :*/}
        //       {/*            <span style={{*/}
        //       {/*              backgroundColor: 'red',*/}
        //       {/*              color: 'white',*/}
        //       {/*              padding: '5px 25px',*/}
        //       {/*              borderRadius: '8px',*/}
        //       {/*            }}>{visit.in_out}</span>*/}
        //       {/*          }*/}
        //
        //       {/*        </div>*/}
        //       {/*        <div style={{*/}
        //       {/*          marginBottom: '10px',*/}
        //       {/*        }}>*/}
        //       {/*          <span style={{marginRight: '15px'}}>Emotion</span>*/}
        //       {/*          <span style={{marginRight: '15px'}}>-</span>*/}
        //       {/*          <span style={{*/}
        //       {/*            backgroundColor: negativeEmotions.includes(visit.emotion) ? 'red' : 'transparent',*/}
        //       {/*            color: 'white',*/}
        //       {/*            padding: '5px 25px',*/}
        //       {/*            borderRadius: '8px'*/}
        //       {/*          }}*/}
        //       {/*          >{visit.emotion}</span>*/}
        //       {/*        </div>*/}
        //       {/*        <ImageFromS3 s3Bucket={visit.s3_bucket_name} s3Key={visit.s3_object_key}/>*/}
        //       {/*      </div>*/}
        //       {/*    </div>*/}
        //       {/*      : ''*/}
        //       {/*  ))*/}
        //       {/*}*/}
        //       {/*</div>*/}
        //     </div>
        //   ),
        // }}
        scroll={{
          x: "max-content",
        }}
        // pagination={{
        //   pageSize: 100,
        //   pageSizeOptions: [100, 200, 300, 400, 500]
        // }}
      />
    </div>
  );
}

export default withAuthenticator(Testing, { hideSignUp: true });
