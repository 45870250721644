import { useUnit } from "effector-react";
import {
  $potentialEmp,
  changeChosenEmpImgModalEv,
  changeChosenPotentialEmpEv,
  getPotentialEmpFx,
} from "../../models/userListModel";
import { useEffect } from "react";
import { Button, Card, Collapse, Popconfirm, Skeleton } from "antd";
import ImageFromS3 from "../../components/ImageFromS3/ImageFromS3";
import { PictureFilled, UserAddOutlined } from "@ant-design/icons";
import style from "./ClientUserList.module.css";
const handleCopy = (hiddenText) => {
  navigator.clipboard
    .writeText(hiddenText)
    .then(() => {
      console.log("Text copied to clipboard:", hiddenText);
    })
    .catch((err) => {
      console.error("Failed to copy: ", err);
    });
};

export const DescItem = ({ label, value, styles }) => {
  return (
    <div className={style.potential_emp_desc} style={styles ? styles : {}}>
      <div className={style.potential_emp_label}>{label}:</div>
      <div className={style.potential_emp_value}>{value}</div>
    </div>
  );
};

export default function PotentialEmp() {
  const potentialEmp = useUnit($potentialEmp);
  const loading = useUnit(getPotentialEmpFx.pending);
  const changeChosenPotentialEmp = useUnit(changeChosenPotentialEmpEv);
  const changeChosenEmpImgModal = useUnit(changeChosenEmpImgModalEv);
  useEffect(() => {
    if (potentialEmp.length === 0) {
      getPotentialEmpFx();
    }
  }, [potentialEmp]);

  if (loading) {
    return (
      <Card style={{ marginTop: "20px" }}>
        <Skeleton active />
      </Card>
    );
  }

  return (
    <>
      <div className={style.potential_emp_subtitle}>
        Данные отображены за последние 7 дней
      </div>
      <div className={style.potential_emp_wrapper}>
        {potentialEmp.map((item) => {
          return (
            <Card
              key={`potential-employee-${item.user_id}`}
              style={{
                position: "relative",
                maxWidth: "30%",
                minWidth: "448px",
              }}
              bodyStyle={{
                padding: "16px",
              }}
            >
              <div className={style.potential_emp_top}>
                <ImageFromS3
                  // bucket_name={item.images[0].bucket_name}
                  // object_key={item.images[0].object_key}
                  presigned_url={
                    item.images.length > 0
                      ? item.images[0]["presigned-url"]
                      : null
                  }
                  // width={180}
                  // height={180}
                />
                <div>
                  <div style={{ display: "flex", columnGap: "20px" }}>
                    <DescItem label={"Статус"} value={item.status} />
                    <DescItem label={"Всего посещений"} value={item.count} />
                  </div>
                  <DescItem
                    label={"Последний визит"}
                    value={item.images.length > 0 ? item.images[0].time : "-"}
                    styles={{ marginTop: "24px" }}
                  />
                </div>
              </div>
              <div className={style.potential_emp_footer}>
                <Button
                  icon={<PictureFilled />}
                  className={style.more_images_button}
                  onClick={() => {
                    changeChosenEmpImgModal(item.user_id);
                  }}
                >
                  Просмотреть все{" "}
                  <div className={style.images_count}>
                    +{item.images.length - 1}
                  </div>
                </Button>
                <Button
                  type={"primary"}
                  icon={<UserAddOutlined />}
                  className={style.make_emp_button}
                  onClick={() =>
                    changeChosenPotentialEmp({
                      user_id: item.user_id,
                    })
                  }
                >
                  Добавить
                </Button>
              </div>
            </Card>
          );
        })}
      </div>
    </>
  );
}
