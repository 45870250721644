import '../../App.css';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { fetchUserAttributes, fetchAuthSession } from 'aws-amplify/auth';
import AWS from 'aws-sdk'
import { PieChartOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useUnit } from 'effector-react';
import {$userGroups} from '../../models/userModel';


function DemoDashboard({ signOut, user }) {
  const dashboardRef = useRef([]);
  const [dashboardId, setDashboardId] = useState('');
  const [embeddedDashboard, setEmbeddedDashboard] = useState(null);
  const [dashboardUrl, setDashboardUrl] = useState(null);
  const [embeddingContext, setEmbeddingContext] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [userName, setUserName] = useState('');
  const [userGroup, setUserGroup] = useState([]);
  const [dashboardData, setDashboardData] = useState(null);

  const navigate = useNavigate();

  // useEffect(() => {
  //
  //   const cloudWatch = new AWS.CloudWatch({
  //     region: 'eu-west-1',
  //     accessKeyId: 'AKIAV7QO7JUKGJF3OBFM',
  //     secretAccessKey: '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',
  //   });
  //
  //   const params = {
  //     DashboardName: 'Backend',
  //   };
  //
  //   // cloudWatch.getDashboard(params, (err, data) => {
  //   //   if (err) {
  //   //     console.log('Error fetching CloudWatch dashboard:', err);
  //   //   } else {
  //   //     console.log('getDashboard data: - ', data)
  //   //     setDashboardData(data.DashboardBody);
  //   //   }
  //   // });
  // }, []);

  useEffect(() => {
    const fetchUserGroup = async () => {
      // debugger
      // console.log('fetchUserGroup');
      try {
        const user = await fetchAuthSession();
        // debugger
        const group = user.tokens.accessToken.payload['cognito:groups'];
        setUserGroup(group || []);
        console.log('group: - ', group[0])
        if(group == 'Customer'){
          navigate('/dashboard')
        }
      } catch (error) {
        console.error('Error fetching user group:', error);
      }
    };

    fetchUserGroup();
  }, []);

  async function handleFetchUserAttributes() {
    try {
      const userAttributes = await fetchUserAttributes();
      // setDashboardId(userAttributes['custom:DashboardId']);
      // await changeDashboard(userAttributes['custom:DashboardId']);
      console.log(userAttributes);
      setUserName(userAttributes['name']);
      return userAttributes
    } catch (error) {
      console.log(error);
    }
  }



  useEffect(() => {
    const timeout = setTimeout(() => {

      fetch("https://9iz15iyde3.execute-api.eu-west-1.amazonaws.com/embed/anonymous-embed-v2"
      ).then((response) => response.json()
      ).then((response) => {
        setDashboardUrl(response.EmbedUrl)
      })
    }, 10);
    return () => clearTimeout(timeout);
  }, []);

  const createContext = async () => {
    const context = await createEmbeddingContext();
    setEmbeddingContext(context);
  }

  useEffect(() => {
    if (dashboardUrl) { createContext() }
  }, [dashboardUrl])

  useEffect(() => {
    if (embeddingContext) { embed(); }
  }, [embeddingContext])
  //FIXME CONFIGURATION FOR 1 CURRENT DASHBOARD
  // const embed = async () => {
  //
  //   let options = {
  //     url: dashboardUrl,
  //     container: dashboardRef.current,
  //     height: "500px",
  //     width: "600px",
  //   };
  //   const userInfo = await handleFetchUserAttributes()
  //
  //   // let newUrl = "https://eu-west-1.quicksight.aws.amazon.com/embed/3bfdf79a968847789735d5d10d5d6570/dashboards/478ed133-4942-4841-8e41-ac3402ef4494?code=AYABeByuSyA7V3oLYsR07pCcR0QAAAABAAdhd3Mta21zAEthcm46YXdzOmttczpldS13ZXN0LTE6MzU2MzA1MTgzMDczOmtleS83ZTYwMTNkMC0xM2I0LTRmODItYmI1NC00YjllMTM4NzczNjYAuAECAQB4IZt0zcr71c1qotPywu6knKQF9RerzWvIw9ebvDgZ4T0BwKSEFgh8_DYRiYK-bHFFPAAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDF9Xuh8dZI6X-MUYRQIBEIA7r9rXlXf8-PkmWUC7Na4wswX5xzH9d7KOHaguUOb3NWCBAWC-RybQxAY4kCtjGogkQZSaRqX2fYkCTlkCAAAAAAwAABAAAAAAAAAAAAAAAAAAhDj3G762sZEVALtwcrFvhv____8AAAABAAAAAAAAAAAAAAABAAAAm20jOJ2M6doZXOS8m6HYcjlmwpiJeYdAE-ptzH6nFappA06SWjoWsBs1dEi5zRGEOpWDSM9oZm06KoX1f3QdrIphH86MrGCMybIAsu148eO8jqcml_P_YRDvDqOejL7OX2yO61WqpJHEZSg-eC-Z5xPyLnH4MdAcckrxFgyi4JY6GEQLJyIHzyniX6WFZCrAjv86CeRcEwkczsDVtiqg_-nwT0paBIj707PCoQ%3D%3D&identityprovider=quicksight&isauthcode=true";
  //   console.log('userInfo: - ', userInfo)
  //   let replacedUrl = options.url.replace(/\/([^\/?]+)\?/, "/" + 'ef112755-3f79-4cd5-9c05-0f3c2a76670e' + "?");
  //   options.url = replacedUrl
  //   console.log('options: - ', options)
  //   const newEmbeddedDashboard = await embeddingContext.embedDashboard(options);
  //
  //   setEmbeddedDashboard(newEmbeddedDashboard);
  // };

  //FIXME CONFIGURATION FOR MULTI CHOICE
  const embed = async (id) => {
    let options = {
      url: dashboardUrl,
      container: dashboardRef.current,
      height: "500px",
      width: "600px",
    };
    const userInfo = await handleFetchUserAttributes()
    // https://eu-west-1.quicksight.aws.amazon.com/embed/c61bf940118d4bb8822043d095262699/dashboards/478ed133-4942-4841-8e41-ac3402ef4494?code=AYABeP9VxKsT-j3oJm1mApgAKFkAAAABAAdhd3Mta21zAEthcm46YXdzOmttczpldS13ZXN0LTE6MzU2MzA1MTgzMDczOmtleS83ZTYwMTNkMC0xM2I0LTRmODItYmI1NC00YjllMTM4NzczNjYAuAECAQB4IZt0zcr71c1qotPywu6knKQF9RerzWvIw9ebvDgZ4T0BaHgqMtvadgyLAc0r78eFswAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDG5mdcl_K-CDeKJGTgIBEIA7eaGqy4-WdtNwQj1rwnLTUaMPPAyPlZNQq_jEuzRAT81GQGaRAnzVm-F9NoTShkOnuchu1YTQrT8RaZ8CAAAAAAwAABAAAAAAAAAAAAAAAAAAqR-bLVg6y3moVB_XuTk0ov____8AAAABAAAAAAAAAAAAAAABAAAAmxaVbsrCVeWUmJK3R-P5eBBU8kH9IdFlxxcp2-kbT3BEPuKWjoFgmkodX6TFylS4M41Ibu5oF_hJSkR8AblpnP4_DQX1v4J74sG_3yYo5Im6DhS_lKsHNIbNwFxw3G5QAC2kul91L6nGGkWs8W9tXYUR_lAJVe4BZ1WIDuYL15cH74bBtSxbiAbdHwQHOslEXeumKGjIQGWskLbLOdidzIVWmK5DoEv9l-SU-g%3D%3D&identityprovider=quicksight&isauthcode=true
    // let newUrl = "https://eu-west-1.quicksight.aws.amazon.com/embed/3bfdf79a968847789735d5d10d5d6570/dashboards/478ed133-4942-4841-8e41-ac3402ef4494?code=AYABeByuSyA7V3oLYsR07pCcR0QAAAABAAdhd3Mta21zAEthcm46YXdzOmttczpldS13ZXN0LTE6MzU2MzA1MTgzMDczOmtleS83ZTYwMTNkMC0xM2I0LTRmODItYmI1NC00YjllMTM4NzczNjYAuAECAQB4IZt0zcr71c1qotPywu6knKQF9RerzWvIw9ebvDgZ4T0BwKSEFgh8_DYRiYK-bHFFPAAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDF9Xuh8dZI6X-MUYRQIBEIA7r9rXlXf8-PkmWUC7Na4wswX5xzH9d7KOHaguUOb3NWCBAWC-RybQxAY4kCtjGogkQZSaRqX2fYkCTlkCAAAAAAwAABAAAAAAAAAAAAAAAAAAhDj3G762sZEVALtwcrFvhv____8AAAABAAAAAAAAAAAAAAABAAAAm20jOJ2M6doZXOS8m6HYcjlmwpiJeYdAE-ptzH6nFappA06SWjoWsBs1dEi5zRGEOpWDSM9oZm06KoX1f3QdrIphH86MrGCMybIAsu148eO8jqcml_P_YRDvDqOejL7OX2yO61WqpJHEZSg-eC-Z5xPyLnH4MdAcckrxFgyi4JY6GEQLJyIHzyniX6WFZCrAjv86CeRcEwkczsDVtiqg_-nwT0paBIj707PCoQ%3D%3D&identityprovider=quicksight&isauthcode=true";
    let replacedUrl = ''
    console.log('embed id: -', id)
    console.log('options: - ', options)
    if(id){
      console.log('replacedUrl if id')

      if(!options.url){
        replacedUrl = "/" + '404bf0c7-c1ee-4039-a64f-bdaf8ec9ba3f' + "?"
      } else {
        // debugger
        replacedUrl = options.url.replace(/\/([^\/?]+)\?/, "/" + userInfo['custom:DashboardId'] + "?");
      }
    } else {
      // debugger
      console.log('replacedUrl if no id')
      replacedUrl = options.url.replace(/\/([^\/?]+)\?/, "/" + '404bf0c7-c1ee-4039-a64f-bdaf8ec9ba3f' + "?");
      console.log('replacedUrl: - ', replacedUrl)
    }
    replacedUrl = options.url.replace(/\/([^\/?]+)\?/, "/" + '404bf0c7-c1ee-4039-a64f-bdaf8ec9ba3f' + "?");
    options.url = replacedUrl
    console.log('options.url: - ',options.url)
    const newEmbeddedDashboard = await embeddingContext.embedDashboard(options);
    // debugger
    setEmbeddedDashboard(newEmbeddedDashboard);
  };

  useEffect(() => {
    if (embeddedDashboard) {
      embeddedDashboard.navigateToDashboard(dashboardId, {})
    }
  }, [dashboardId])

  const changeDashboardBySelect = async (e) => {
    const dashboardId = e
    setDashboardId(dashboardId)
  }

  const changeDashboardByEffect = async (e) => {
    const dashboardId = e
    setDashboardId(dashboardId)
  }

  useEffect(() => {
    (async () => {
      try {
        const userDashboardId= await handleFetchUserAttributes();
        console.log(userDashboardId)
        changeDashboardByEffect(userDashboardId)
      } catch (err) {
        console.log('Error occured when fetching books');
      }
    })();
  }, []);

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }
  const items = [
    getItem('Dashboard', '1', <PieChartOutlined />),

    // getItem('Option 2', '2', <DesktopOutlined />),
    // getItem('User', 'sub1', <UserOutlined />, [
    //   getItem('Tom', '3'),
    //   getItem('Bill', '4'),
    //   getItem('Alex', '5'),
    // ]),
    // getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
    // getItem('Files', '9', <FileOutlined />),
  ];



  return (
    <div>
      <Select
        defaultValue={'404bf0c7-c1ee-4039-a64f-bdaf8ec9ba3f'}
        style={{
          width: '250px',
          marginBottom: '25px'
        }}
        placeholder="Select a Dashboard"
        optionFilterProp="label"
        // style={{ width: 120 }}
        // value={dashboardId}
        onChange={changeDashboardBySelect}
        options={[
          {value: '404bf0c7-c1ee-4039-a64f-bdaf8ec9ba3f', label: 'Education Demo English'},
          {value: 'ef112755-3f79-4cd5-9c05-0f3c2a76670e', label: 'Education Demo'},
          {value: '289f31ca-8d74-4b1f-b605-4e45544860dd', label: 'Demo Device'},
          {value: '62cf602f-a77e-4d3b-aa0a-e4ef0489f9f0', label: 'HR Solution'},
          { value: '59225a74-dd26-40d6-9bae-d7f366b36094', label: 'Investor Demo' },
        ]}
      />
      <div ref={dashboardRef}/>
    </div>

  );
};

export default withAuthenticator(DemoDashboard, {hideSignUp: true});