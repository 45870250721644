import {sample} from "effector";
import {setUserGroupsEv, setUserKeysEv} from "./events";
import {$userGroups, $userKeys} from "./stores";

sample({
	clock: setUserGroupsEv,
	target: $userGroups
})

sample({
	clock: setUserKeysEv,
	target: $userKeys
})
