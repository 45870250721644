import { createBrowserRouter } from "react-router-dom";
import App from "../App.js";
import Dashboard from "../pages/Dashboard/Dashboard";
import DemoVideo from "../pages/DemoVideo/DemoVideo";
import Testing from "../pages/Testing/Testing";
import TestingClient from "../pages/TestingClient/TestingClient";
import UnrecognizedTesting from "../pages/UnrecognizedTesting/UnrecognizedTesting";
import SearchById from "../pages/SearchById/SearchById";
import FaceRec from "../pages/FaceRec/FaceRec";
import Registration from "../pages/Registration/Registration";
import DashboardHR from "../pages/DashboardHR/DashboardHR";
import Login from "../pages/CustomLogin/CustomLogin";
import Duplicates from "../pages/Duplicates/Duplicates";
import UserList from "../pages/UserList/UserList";
import DemoDashboard from "../pages/DemoDashboard/DemoDashboard";
import ClientUserList from "../pages/ClientUserList/ClientUserList";
import SchoolUserList from "../pages/SchoolUserList/SchoolUserList";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { path: "/dashboard", element: <Dashboard /> },
      { path: "/", element: <DemoDashboard /> },
      { path: "/login", element: <Login /> },
      // { path: '/', element: <Dashboard /> },
      { path: "/demo-video", element: <DemoVideo /> },
      { path: "/testing", element: <Testing /> },
      { path: "/testing-client", element: <TestingClient /> },
      { path: "/unrecognized-testing", element: <UnrecognizedTesting /> },
      { path: "/search-by-id", element: <SearchById /> },
      { path: "/face-rec", element: <FaceRec /> },
      { path: "/registration", element: <Registration /> },
      { path: "/hr-event", element: <DashboardHR /> },
      { path: "/demo-dashboard", element: <DemoDashboard /> },
      // { path: '/client-card', element: <ClientCard /> },
      // { path: '/partner-card', element: <PartnerCard /> },
      { path: "/duplicates", element: <Duplicates /> },
      { path: "/user-list", element: <UserList /> },
      { path: "/client-user-list", element: <ClientUserList /> },
      { path: "/user-list-edu", element: <SchoolUserList /> },
    ],
  },
  // {
  //   path: '/login',
  //   element: <Login />,
  // },
  // {
  //   path: '/register',
  //   element: <Register />,
  // },
]);

export default routes;
