import {Flex, Link, Text, useTheme} from "@aws-amplify/ui-react";
import footer_badge from '../../images/footer_badge 1.png'

export function Footer() {
  const { tokens } = useTheme();

  return (
    <div padding={tokens.space.medium}>
      <Text style={{textAlign: 'center', marginTop: '15px'}}><Link >Правила и соглашения</Link></Text>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '125px',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <img
          style={{
            maxWidth: '100px',
            marginBottom: '125px'
          }}
          src={footer_badge}/>
        <div>@FOQUS 2024</div>
      </div>
    </div>
  );
}
