import { Modal } from "antd";
import { useUnit } from "effector-react/compat";
import {
  $chosenEmpImgModal,
  $potentialEmp,
  changeChosenEmpImgModalEv,
} from "../../models/userListModel";
import style from "./SchoolUserList.module.css";
import ImageFromS3 from "../../components/ImageFromS3/ImageFromS3";
import { DescItem } from "./PotentialEmp";

export default function PotentialImagesModal() {
  const chosen_id = useUnit($chosenEmpImgModal);
  const potentialEmp = useUnit($potentialEmp);
  const changeChosenEmpImgModal = useUnit(changeChosenEmpImgModalEv);
  const images = chosen_id
    ? potentialEmp.find((emp) => emp.user_id === chosen_id).images
    : [];

  return (
    <Modal
      open={!!chosen_id}
      footer={null}
      onCancel={() => changeChosenEmpImgModal(null)}
      width={794}
    >
      <div className={style.img_modal_title}>Все изображения</div>
      <div className={style.img_modal_img_wrapper}>
        {images.map((item) => (
          <ImageItem
            // bucket_name={item.bucket_name}
            // object_key={item.object_key}
            time={item.time}
            presigned_url={item["presigned-url"]}
          />
        ))}
      </div>
    </Modal>
  );
}

const ImageItem = ({ bucket_name, object_key, time, presigned_url }) => {
  return (
    <div>
      <ImageFromS3 presigned_url={presigned_url} />
      <DescItem
        label={"ВРЕМЯ ВИЗИТА"}
        value={time}
        styles={{ marginTop: "21px" }}
      />
    </div>
  );
};
