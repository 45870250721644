import { sample } from "effector";
import {
  createEduUserFx,
  getPotentialEmpFx,
  getUserListEduFx,
  getUserListFx,
  sendEditUserFx,
} from "./effects";
import {
  $chosenEmpImgModal,
  $chosenPotentialEmp,
  $chosenUser,
  $potentialEmp,
  $showCreateModal,
  $userEmpCategory,
  $userList,
  $userListEdu,
  $userListPlaceId,
} from "./stores";
import {
  changeChosenEmpImgModalEv,
  changeChosenPotentialEmpEv,
  changeChosenUserEv,
  changeShowCreateModalEv,
  changeUserEmpCategoryEv,
  changeUserListPlaceIdEv,
  createEduUserEv,
  fireEmployeeEv,
} from "./events";
import { formatUserListTable } from "./utils";

sample({
  clock: getUserListFx.doneData,
  fn: formatUserListTable,
  target: $userList,
});

sample({
  clock: $userListPlaceId.updates,
  target: getUserListFx,
});

sample({
  clock: $userListPlaceId.updates,
  target: getPotentialEmpFx,
});

sample({
  clock: changeUserListPlaceIdEv,
  fn: (clock) => {
    return clock.value;
  },
  target: $userListPlaceId,
});

sample({
  clock: changeUserEmpCategoryEv,
  fn: (clock) => {
    return clock.value;
  },
  target: $userEmpCategory,
});

sample({
  clock: changeChosenUserEv,
  target: $chosenUser,
});

sample({
  clock: sendEditUserFx.doneData,
  filter: (clock) => clock,
  fn: (clock) => {
    if (clock) return null;
  },
  target: $chosenUser,
});

sample({
  source: $userList,
  clock: sendEditUserFx.doneData,
  filter: (source, clock) => clock,
  fn: (source, clock) => {
    const clock_partition_key = clock.partition_key.split("#")[1];
    const changedUser = source.find(
      (user) => user.partition_key === clock_partition_key,
    );
    return [
      ...source.filter((user) => user.partition_key !== clock_partition_key),
      {
        ...changedUser,
        ...clock,
        partition_key: clock_partition_key,
        sort_key: clock.sort_key.split("#")[1],
      },
    ];
  },
  target: $userList,
});

sample({
  clock: getPotentialEmpFx.doneData,
  fn: (clock) => {
    if (Array.isArray(clock)) {
      return clock;
    }
    return [clock];
  },
  target: $potentialEmp,
});

sample({
  clock: fireEmployeeEv,
  fn: (clock) => {
    return {
      ...clock,
      emp_category: "Уволен",
    };
  },
  target: sendEditUserFx,
});

sample({
  source: $showCreateModal,
  clock: changeShowCreateModalEv,
  fn: (source, clock) => {
    if (typeof clock === "boolean") {
      return clock;
    }
    return !source;
  },
  target: $showCreateModal,
});

sample({
  clock: changeChosenPotentialEmpEv,
  target: $chosenPotentialEmp,
});

sample({
  clock: changeChosenEmpImgModalEv,
  target: $chosenEmpImgModal,
});

sample({
  clock: createEduUserEv,
  target: createEduUserFx,
});

sample({
  clock: createEduUserFx.doneData,
  fn: () => false,
  target: $showCreateModal,
});

sample({
  clock: getUserListEduFx.doneData,
  fn: (clock) => {
    return clock.map((item, index) => ({
      ...item,
      key: `edu-item-${index}`,
    }));
  },
  target: $userListEdu,
});

sample({
  clock: $userEmpCategory.updates,
  fn: (clock) => clock,
  target: getUserListEduFx,
});
