import {
  Authenticator,
  Flex,
  Grid,
  Image,
  useTheme,
  View
} from "@aws-amplify/ui-react";
import style from './CustomLogin.module.css'

import { Header } from "./Header";
import { Footer } from "./Footer";
import { SignInHeader } from "./SignInHeader";
import { SignInFooter } from "./SignInFooter";
import logoLogin from '../../images/logoforlogin.png'
import { I18n } from 'aws-amplify/utils';
import { translations } from '@aws-amplify/ui-react';
// I18n.putVocabularies(translations);
I18n.setLanguage('ru');

I18n.putVocabulariesForLanguage('ru', {
  // 'Sign In': 'Войти', // Tab header
  'Sign in': 'Войти', // Button label
  'Reset password': 'Forgot your password?',
  // 'Sign in to your account': 'Welcome Back!',
  // Username: 'Enter your username', // Username label
  // Password: 'Enter your password', // Password label
  // 'Forgot your password?': 'Забыли пароль?',
  // 'Reset your password': 'bla'
  // 'Reset your password': 'Forgot your password?',
});
// });

const components = {
  // Header,
  SignIn: {
    Header: SignInHeader,
    Footer: SignInFooter
  },
  Footer,

};

const formFields = {
  signIn: {
    username: {
      label: '',
      placeholder: 'Имя пользователя',
    },
    password: {
      label: '',
      placeholder: 'Пароль',
      isRequired: false,
      // order: 2,
    },
    // confirm_password: {
    //   label: 'Подтвердите пароль',
    //   order: 1,
    // }
  },
  // signUp: {
  //   password: {
  //     label: 'Пароль',
  //     placeholder: 'Пароль',
  //     isRequired: false,
  //     order: 2,
  //   },
  //   confirm_password: {
  //     label: 'Подтвердите пароль',
  //     order: 1,
  //   },
  // },
}

// I18n.putVocabulariesForLanguage('en', {
//   'Sign In': 'Login', // Tab header
//   'Sign in': 'Log in', // Button label
//   'Sign in to your account': 'Welcome Back!',
//   Username: 'Имя пользователя', // Username label
//   Password: 'Пароль', // Password label
//   'Forgot your password?': 'Reset Password',
// });

function Login() {
  const { tokens } = useTheme();

  return (
    <Grid templateColumns={{ base: "1fr 0", medium: "1fr 1fr" }}>
      <View height="100vh" className={style.loginBackground}>
        <div className={style.wrapperLoginPageForLeftSide}>
          <img src={logoLogin}/>

          <div className={style.mainTitle}>
            Behavioral<br/> intelligenge<br/>
            at scale
          </div>

          {/*<div className={style.secondTitle}>*/}
          {/*  For HR:*/}
          {/*</div>*/}

          <div className={style.loginText}>
            Unlock the full potential of your workforce <br/> with our Marketing module. Learn how to align HR and Marketing for maximum impact.
          </div>

          {/*<div className={style.secondTitle}>*/}
          {/*  For Marketing:*/}
          {/*</div>*/}

          <div className={style.loginText}>
            Discover how our platform can help other departments.<br/> Refer a colleague and get rewarded. Our customization modules include HR, Marketing and Security.
          </div>
        </div>
      </View>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Authenticator
          style={{
            borderRadius: '8px'
          }}
          formFields={formFields} components={components} hideSignUp
        >
          {({signOut, user}) => (
            <main>
              <h1>Hello {user.username}</h1>
              <button onClick={signOut}>Sign out</button>
            </main>
          )}
        </Authenticator>
      </div>
    </Grid>
  );
}

export default Login