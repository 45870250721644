import { createEffect } from "effector";
import { notification } from "antd";

export const getUserListFx = createEffect().use(async (payload) => {
  try {
    const response = await fetch(
      "https://5zbcsztftk.execute-api.eu-west-1.amazonaws.com/default/GetUserListByPlaceId",
      {
        method: "POST",
        body: JSON.stringify({
          sort_key: payload ? `PLACEID#${payload}` : "PLACEID#amaa25d7c9",
        }),
      },
    );

    let result = await response.json();
    const tempRes = JSON.parse(result.body);
    console.log("getUserListFx:: Parsed body", tempRes);
    return tempRes;
  } catch (e) {
    console.error(e);
  }
});

export const sendEditUserFx = createEffect().use(async (payload) => {
  try {
    const response = await fetch(
      "https://i8wkv4zm9k.execute-api.eu-west-1.amazonaws.com/ChangeInfoOnEmployees/EmployeeInfo",
      {
        method: "POST",
        body: JSON.stringify({
          action: "update",
          values: {
            user_id: payload.partition_key,
            place_id: payload.sort_key,
            new_department: payload.emp_department,
            new_position: payload.emp_position,
            new_category: payload.emp_category,
          },
        }),
      },
    );

    let result = await response.json();
    console.log("sendEditUserFx:: resiult", result);
    if (result.statusCode === "200") {
      notification.success({
        message: "Success",
        description: "User successfully changed",
        placement: "topRight",
        maxCount: 1,
      });
    } else {
      notification.error({
        message: "Error",
        description: "Something went wrong...",
        placement: "topRight",
        maxCount: 1,
      });
    }
    return result.body;
  } catch (e) {
    console.error(e);
  }
});

export const getPotentialEmpFx = createEffect().use(async (payload) => {
  try {
    const response = await fetch(
      "https://5zbcsztftk.execute-api.eu-west-1.amazonaws.com/default/GetPotentialEmployeesByPlaceId\n",
      {
        method: "POST",
        body: JSON.stringify({
          client_id: payload ? payload : "amaa25d7c9",
        }),
      },
    );

    let result = await response.json();
    return result.data;
  } catch (e) {
    console.error(e);
  }
});

export const createEmpFx = createEffect().use(async (payload) => {
  try {
    const response = await fetch(
      "https://i8wkv4zm9k.execute-api.eu-west-1.amazonaws.com/ChangeInfoOnEmployees/EmployeeInfo",
      {
        method: "POST",
        body: JSON.stringify({
          action: "create_from_potential",
          values: payload,
        }),
      },
    );

    let result = await response.json();
    console.log("sendEditUserFx:: resiult", result);
    if (result.statusCode === "200") {
      notification.success({
        message: "Success",
        description: "User successfully changed",
        placement: "topRight",
        maxCount: 1,
      });
    } else {
      notification.error({
        message: "Error",
        description: "Something went wrong...",
        placement: "topRight",
        maxCount: 1,
      });
    }
    return result.body;
  } catch (e) {
    console.error(e);
  }
});

export const createUserFx = createEffect().use(async (payload) => {
  try {
    const response = await fetch(
      "https://i8wkv4zm9k.execute-api.eu-west-1.amazonaws.com/ChangeInfoOnEmployees/EmployeeInfo",
      {
        method: "POST",
        body: JSON.stringify({
          action: "create_from_form",
          values: payload,
        }),
      },
    );

    let result = await response.json();
    console.log("sendEditUserFx:: resiult", result);
    if (result.statusCode === "200") {
      notification.success({
        message: "Success",
        description: "User successfully changed",
        placement: "topRight",
        maxCount: 1,
      });
    } else {
      notification.error({
        message: "Error",
        description: "Something went wrong...",
        placement: "topRight",
        maxCount: 1,
      });
    }
    return result.body;
  } catch (e) {
    console.error(e);
  }
});

export const createEduUserFx = createEffect().use(async (payload) => {
  try {
    const response = await fetch(
      "https://ste1h7g7s7.execute-api.eu-west-1.amazonaws.com/LoyaltySystem1/UserRecords",
      {
        method: "POST",
        body: JSON.stringify(payload),
      },
    );

    let result = await response.json();
    console.log("createEduUserFx:: result", result);
    return true;
  } catch (e) {
    console.error(e);
  }
});

export const getUserListEduFx = createEffect().use(async (payload) => {
  try {
    const response = await fetch(
      "https://5zbcsztftk.execute-api.eu-west-1.amazonaws.com/default/GetUserListByPlaceIdEDU",
      {
        method: "POST",
        body: JSON.stringify({
          sort_key: "zasa05e1p1",
          emp_category: payload ? payload : "Ученик",
        }),
      },
    );

    let result = await response.json();
    const tempRes = JSON.parse(result.body);
    console.log("getUserListEduFx:: Parsed body", tempRes);
    return tempRes;
  } catch (e) {
    console.error(e);
  }
});
